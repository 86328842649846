import { giphyWhite } from '@giphy/colors'
import { Gif as SDKGif } from '@giphy/react-components'
import { useRef } from 'react'
import { useHistory } from 'react-router'
import VideoPreviewPlayInline from 'shared/components/video-inline-play'
import { grid3 } from 'shared/util/grid'
import { relativeUrl } from 'shared/util/url'
import styled, { css as styledCss } from 'styled-components'
import css from './carousel-item.css'

const GRADIENT_VARIANTS = 9

const Gif = styled(SDKGif)`
    img {
        object-fit: ${(props) => (props.isSticker ? `contain` : `cover`)};
    }
`

const ItemLabel = styled.a`
    color: ${giphyWhite};
    font-size: 14px;
    font-weight: 600;
    height: 28px;
    line-height: 26px;
    overflow: hidden;
    padding: 0 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    bottom: 0;
    box-sizing: border-box;
    left: 0;
    margin: 0;
    position: absolute;
    width: 100%;
    z-index: 2;
    &:hover {
        color: ${giphyWhite};
    }
    &:before {
        content: '';
        width: 100%;
        height: 100%;
        background-color: #121212;
        position: absolute;
        margin-left: -10px;
    }
`

const Item = styled.div`
    height: 100%;
    overflow: hidden;
    text-align: left;
    display: block;
    font-size: 14px;
    font-weight: 600;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    ${(props) =>
        props.isLabel &&
        styledCss`
            padding-bottom: 28px;
        `}
`

export const getOpacity = (index) => {
    let opacity = index % GRADIENT_VARIANTS
    return opacity
}

const ChannelCarouselItem = ({ gif, itemIndex: { index, max }, backgroundColor, onGifClick, className = '' }) => {
    const opacity = useRef(getOpacity(index, max))
    const history = useHistory()
    const onClick =
        onGifClick ||
        ((gif, e) => {
            e.preventDefault()

            if (gif.label) {
                location.href = gif.url
            } else {
                history.push(relativeUrl(gif.url))
            }
        })

    return (
        <div data-opacity={opacity.current} className={`${css.itemWrapper} ${className}`}>
            <Item isLabel={!!gif.label}>
                {gif.is_video ? (
                    <VideoPreviewPlayInline
                        gif={gif}
                        width={grid3}
                        height={grid3 * (9 / 16)}
                        onClick={(e) => onClick(gif, e)}
                        hideLinkAndCopy
                        noLink
                    />
                ) : (
                    <Gif
                        isSticker={gif.is_sticker}
                        gif={gif}
                        width={grid3}
                        height={grid3 * (9 / 16)}
                        backgroundColor={!gif.is_sticker ? backgroundColor : undefined}
                        onGifClick={onClick}
                        hideAttribution
                    />
                )}
                {gif.label && (
                    <ItemLabel
                        data-opacity={opacity.current}
                        style={{ backgroundColor }}
                        href={gif.url}
                        onClick={(e) => onClick(gif, e)}
                    >
                        <span className={css.itemLabelText}>{gif.label}</span>
                    </ItemLabel>
                )}
            </Item>
            {gif.is_private && (
                <a href={gif.path || gif.url} className={css.privateOverlay}>
                    <i className={css.privateIcon} />
                </a>
            )}
        </div>
    )
}

export default ChannelCarouselItem
