import { omit } from 'lodash'
import { useContext, useState } from 'react'
import { connect } from 'react-redux'
import { ResponseError } from 'shared/api/errors'
import { flattenResponse } from 'shared/components/form/fields'
import { message } from 'shared/redux/message'
import { AckResponseErrorHandler, SaveHandler } from 'shared/types/settings'
import styled from 'styled-components'
import { IProfilePowerUser } from 'types'
import { FlashMessageContext } from '../flash-message/flash-message'
import ChannelSettingsForm from './channel-settings-form'
import DeleteAccount from './delete-account'
import Form from './settings-form'

const Container = styled.div`
    flex: 1;
`

const FormWithMargin = styled(Form)`
    margin-bottom: 30px;
`

const ChannelFormWithMargin = styled(ChannelSettingsForm)`
    margin-bottom: 30px;
`

const SHOW_DELETE = true

const defaultMessage = { error: 'Failed to update user settings', success: `Your settings were saved :)` }

const Settings = ({ user }) => {
    const [responseErrors, setResponseErrors] = useState<object>({})

    const { showMessage } = useContext(FlashMessageContext)

    const saveHandler: SaveHandler = (error) => {
        // default error message
        let errorMessage = error ? defaultMessage.error : ''
        // response error contains details
        const responseError = error as ResponseError
        if (responseError && responseError.details) {
            setResponseErrors(flattenResponse(responseError.details))
        }

        if (error) {
            showMessage({ messageType: 'failure', message: errorMessage })
        } else {
            showMessage({ messageType: 'success', message: defaultMessage.success })
        }
    }
    const ackResponseError: AckResponseErrorHandler = (field) => {
        // Callback for `Fields` to notify this Component to remove a server-side field error,
        // once a user has seen it and changed the field value
        if (responseErrors[field]) {
            setResponseErrors(omit(responseErrors, field))
        }
    }
    return (
        <Container>
            <FormWithMargin
                user={user as IProfilePowerUser}
                saveHandler={saveHandler}
                ackResponseError={ackResponseError}
                responseErrors={responseErrors}
            />
            <ChannelFormWithMargin
                user={user as IProfilePowerUser}
                saveHandler={saveHandler}
                ackResponseError={ackResponseError}
                responseErrors={responseErrors}
            />
            {SHOW_DELETE && <DeleteAccount user={user} />}
        </Container>
    )
}

export default connect(undefined, { message })(Settings)
